import ApiService from '@/api/new/services/api.service'
import i18n from '@/i18n'
import { router } from '@/router/index'
import store from '@/store/store'

const state = {}

const mutations = {}

const actions = {
  async getCopywritingTask({ rootGetters }, id) {
    const supplierId = rootGetters['supplier/supplierId']
    await ApiService.get(
      `suppliers/${supplierId}/tasks/${id}/review-copywriting`
    )
      .then((res) => {
        store.commit('contentGeneration/setCurrentDocument', res.data.data)
        store.commit(
          'contentGeneration/setCurrentTemplate',
          res.data.data.attributes.template
        )
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async updateCopywritingTask({ rootGetters }, payload) {
    const supplierId = rootGetters['supplier/supplierId']
    const data = {
      data: {
        type: 'content_generation_document',
        attributes: { ...payload.attributes }
      }
    }
    await ApiService.put(
      `suppliers/${supplierId}/tasks/${payload.id}/review-copywriting`,
      data
    )
      .then((res) => {
        store.commit('contentGeneration/setCurrentDocument', res.data.data)
        store.commit(
          'contentGeneration/setCurrentTemplate',
          res.data.data.attributes.template
        )
        store.dispatch('toast/success', {
          message: i18n.t('supplier.save_document.toast')
        })
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async finishCopywritingRevision({ rootGetters, dispatch }, id) {
    const supplierId = rootGetters['supplier/supplierId']
    const data = {
      data: {
        type: 'task_revision',
        attributes: {
          status: 'finished_by_supplier',
          changes: []
        }
      }
    }
    dispatch('loading/startLoader', {}, { root: true })
    await ApiService.post(`suppliers/${supplierId}/tasks/${id}/review`, data)
      .then(async () => {
        await dispatch(
          'supplierDashboard/getTasks',
          { page: 1, perPage: 15, search: undefined },
          { root: true }
        )
        await router.push({
          name: 'supplierDashboard'
        })
        store.dispatch('toast/success', {
          message: i18n.t('supplier.finish_copywriting.toast')
        })
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
      .finally(() => {
        dispatch('loading/stopLoader', {}, { root: true })
      })
  }
}

export const copyediting = {
  namespaced: true,
  state,
  actions,
  mutations
}
