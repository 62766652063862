import ApiService from '@/api/new/services/api.service'
import i18n from '@/i18n'
import store from '@/store/store'

const state = {
  subscription: null,
  couponDiscount: 0
}

const mutations = {
  setSelectedSub(state, sub) {
    state.subscription = sub
  },
  setCouponDiscount(state, couponDiscount) {
    state.couponDiscount = couponDiscount
  }
}

const actions = {
  async upgradeSubscription({ rootGetters, dispatch, commit }, payload) {
    const data = {
      data: {
        type: 'account_subscription',
        attributes: {
          subscription_plan: payload.plan,
          billing_period: payload.period,
          coupon: payload.coupon
        }
      }
    }
    await ApiService.post(
      `teams/${rootGetters['workspace/currentAccountId']}/subscriptions`,
      data
    )
      .then((res) => {
        if (res.status == 200) {
          dispatch('account/getCustomerAccount', {}, { root: true })
        }
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async updateSubscription({ rootGetters, dispatch, commit }, payload) {
    const data = {
      data: {
        type: 'account_subscription',
        attributes: {}
      }
    }
    if (payload.plan) {
      data.data.attributes = {
        subscription_plan: payload.plan,
        started_at: payload.started_at
      }
    } else {
      data.data.attributes = {
        task_type_multipliers: {
          ...payload.task_type_multipliers,
          review: 1,
          qa: 1
        }
      }
    }
    await ApiService.put(
      `teams/${rootGetters['workspace/currentAccountId']}/subscriptions/${payload.id}`,
      data
    )
      .then((res) => {
        if (res.status == 200) {
          dispatch('account/getCustomerAccount', {}, { root: true })
          dispatch('payment/getBilling', {}, { root: true })
          store.dispatch('toast/success', {
            message: i18n.t('customer.subscription.update.success')
          })
        }
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  }
}

export const subscriptions = {
  namespaced: true,
  actions,
  mutations,
  state
}
