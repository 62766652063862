import ApiService from '@/api/new/services/api.service'
import i18n from '@/i18n'
import moment from 'moment'
import _ from 'underscore'

const state = {
  allRoles: [],
  selectedRole: {},
  permissionList: [],
  userAccess: {},
  userList: [],
  selectedTeamUser: {},
  selectedTeamUserAccounts: [],
  teamRequests: []
}

const mutations = {
  setAllRoles(state, roles) {
    state.allRoles = roles
  },
  setRole(state, role) {
    state.selectedRole = role
  },
  setAllPermissions(state, permissions) {
    state.permissionList = permissions
  },
  setUserAccess(state, access) {
    state.userAccess = access
  },
  setUserList(state, list) {
    state.userList = list
  },
  setSelectedTeamUser(state, teamUser) {
    state.selectedTeamUser = teamUser
  },
  setSelectedTeamAccounts(state, accounts) {
    state.selectedTeamUserAccounts = accounts
  },
  setTeamRequests(state, requests) {
    state.teamRequests = requests
  }
}

const actions = {
  async getStateTeamUser({ dispatch, state }, customerId) {
    if (
      Object.keys(state.selectedTeamUser).length === 0 ||
      customerId != state.selectedTeamUser.id
    )
      await dispatch('getTeamUser', customerId)
  },
  async getTeamUser({ commit, rootGetters }, customerId) {
    await ApiService.get(
      `teams/${rootGetters['workspace/currentAccountId']}/customers/${customerId}`
    )
      .then((res) => {
        const user = res.data.data
        const accounts = res.data.included
        commit('setSelectedTeamUser', user)
        commit('setSelectedTeamAccounts', accounts)
      })
      .catch((err) => {
        console.log('error', err)
        throw new Error(JSON.stringify(err))
      })
  },
  async getStateUserList({ dispatch, state }) {
    if (state.userList.length === 0) await dispatch('getUserList')
  },
  async getUserList({ commit, rootGetters }) {
    await ApiService.get(
      `teams/${rootGetters['workspace/currentAccountId']}/customers?page=1`
    )
      .then((res) => {
        const list = res.data.data
        commit('setUserList', list)
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async getCustomerCompanyDetail({ commit, rootGetters }, { customerId }) {
    await ApiService.get(
      `teams/${rootGetters['workspace/currentAccountId']}/customers/${customerId}`
    )
      .then(async (res) => {
        const access = res.data.data.attributes.access
        await commit('setUserAccess', access)
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async getStateAllRoles({ dispatch, state }) {
    if (state.allRoles.length === 0) await dispatch('getAllRoles')
  },
  async getAllRoles({ commit, rootGetters }) {
    await ApiService.get(
      `teams/${rootGetters['workspace/currentAccountId']}/roles`
    )
      .then((res) => {
        const allRoles = res.data.data
        const sortByName = _.sortBy(
          allRoles,
          (obj) => obj.attributes.display_name
        )
        commit('setAllRoles', sortByName)
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async getRole({ commit, rootGetters }, roleId) {
    await ApiService.get(
      `teams/${rootGetters['workspace/currentAccountId']}/roles/${roleId}`
    )
      .then((res) => {
        const role = res.data.data
        commit('setRole', role)
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async createNewRole(
    { dispatch, rootGetters },
    { permissionIds, roleName, roleDescription }
  ) {
    const data = {
      data: {
        type: 'role',
        attributes: {
          display_name: roleName,
          description: roleDescription,
          permission_ids: permissionIds
        }
      }
    }

    await ApiService.post(
      `teams/${rootGetters['workspace/currentAccountId']}/roles`,
      data
    )
      .then(() => {
        dispatch('getAllRoles')
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async submitUpdateRole(
    { dispatch, rootGetters },
    { permissionIds, roleName, roleDescription, roleId }
  ) {
    const data = {
      data: {
        type: 'role',
        id: roleId,
        attributes: {
          display_name: roleName,
          description: roleDescription,
          permission_ids: permissionIds
        }
      }
    }

    await ApiService.put(
      `teams/${rootGetters['workspace/currentAccountId']}/roles/${roleId}`,
      data
    )
      .then(() => {
        dispatch('getAllRoles')
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async getStateAllPermissions({ dispatch, state }) {
    if (state.permissionList.length === 0) await dispatch('getAllPermissions')
  },
  async getAllPermissions({ commit, rootGetters }) {
    await ApiService.get(
      `teams/${rootGetters['workspace/currentAccountId']}/permissions`
    )
      .then((res) => {
        const permissions = res.data.data
        commit('setAllPermissions', permissions)
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async updateUserRole(
    { rootGetters },
    { customerId, roleIds, permissionIds }
  ) {
    const data = {
      data: {
        type: 'customer',
        id: customerId,
        attributes: {
          role_ids: roleIds === undefined ? [] : roleIds,
          permission_ids: permissionIds === undefined ? [] : permissionIds
        }
      }
    }
    await ApiService.put(
      `teams/${rootGetters['workspace/currentAccountId']}/customers/${customerId}/roles`,
      data
    )
      .then(() => {})
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async removeUser({ dispatch, rootGetters }, { userId }) {
    await ApiService.delete(
      `teams/${rootGetters['workspace/currentAccountId']}/customers/${userId}`
    )
      .then(() => {
        dispatch('getUserList')
        dispatch(
          'toast/success',
          { message: i18n.t('customer.toast.success.remove_user') },
          { root: true }
        )
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async updateCustomer({ dispatch, rootGetters }, payload) {
    const {
      first_name: oldFirstName,
      last_name: oldLastName,
      phone: oldPhone,
      email: oldEmail,
      timezone: oldTimezone,
      platform_language: oldLanguage
    } = payload.oldCustomerData
    const { first_name, last_name, phone, email, timezone, platform_language } =
      payload.newCustomerData
    const requestData = {
      data: {
        type: 'customer',
        id: payload.id,
        attributes: {
          ...(payload.currentPassword
            ? { current_password: payload.currentPassword }
            : {}),
          ...(first_name !== oldFirstName ? { first_name } : {}),
          ...(last_name !== oldLastName ? { last_name } : {}),
          ...(email !== oldEmail ? { email } : {}),
          ...(phone !== oldPhone ? { phone } : {}),
          ...(timezone !== oldTimezone ? { timezone } : {}),
          ...(platform_language !== oldLanguage ? { platform_language } : {})
        }
      }
    }
    await ApiService.put(
      `teams/${rootGetters['workspace/currentAccountId']}/customers/${payload.id}`,
      requestData
    )
      .then(async () => {
        await dispatch('workspace/getCurrentUser', null, { root: true })
        dispatch(
          'toast/success',
          {
            title: i18n.t('shared.toast.success.saved_title'),
            message: i18n.t('customer.toast.success.update_profile')
          },
          { root: true }
        )
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async deleteTeam({ dispatch, rootGetters }, options) {
    const data = {
      data: {
        type: 'account',
        attributes: {
          gdpr: options
        }
      }
    }
    await ApiService.post(
      `teams/${rootGetters['workspace/currentAccountId']}/gdpr/request-delete`,
      data
    )
      .then(() => {
        dispatch(
          'toast/success',
          { message: i18n.t('shared.toast.success.requesst_delete') },
          { root: true }
        )
        dispatch('workspace/getCurrentUser', {}, { root: true })
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async updateDataRetention({ dispatch, rootGetters }, payload) {
    const data = {
      data: {
        type: 'account',
        attributes: {
          data_retention: {
            type: payload
          }
        }
      }
    }
    await ApiService.post(
      `teams/${rootGetters['workspace/currentAccountId']}/gdpr/data-retention`,
      data
    )
      .then(() => {
        dispatch(
          'toast/success',
          { message: i18n.t('shared.toast.success.data_retention') },
          { root: true }
        )
        dispatch('account/getCustomerAccount', {}, { root: true })
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async getStateTeamRequests({ dispatch, state }) {
    if (state.teamRequests.length === 0) await dispatch('getTeamRequests')
  },
  async getTeamRequests({ commit, rootGetters }) {
    await ApiService.get(
      `teams/${rootGetters['workspace/currentAccountId']}/requests`
    )
      .then((res) => {
        commit('setTeamRequests', res.data.data)
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async acceptTeamRequest(
    { dispatch, rootGetters },
    { requestId, accountIds }
  ) {
    const data = {
      data: {
        type: 'user_invitations',
        attributes: {
          accounts: [...accountIds]
        }
      }
    }
    await ApiService.post(
      `teams/${rootGetters['workspace/currentAccountId']}/requests/${requestId}/accept`,
      data
    )
      .then(async () => {
        await dispatch('getTeamRequests')
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async declineTeamRequest({ dispatch, rootGetters }, requestId) {
    await ApiService.post(
      `teams/${rootGetters['workspace/currentAccountId']}/requests/${requestId}/decline`
    )
      .then(async () => {
        await dispatch('getTeamRequests')
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async sendPlgGoalForm({ dispatch, rootGetters }, payload) {
    const data = {
      data: {
        type: 'accounts',
        attributes: {
          ...payload
        }
      }
    }
    await ApiService.post(
      `teams/${rootGetters['workspace/currentAccountId']}/plg-goal`,
      data
    )
      .then(async () => {
        await dispatch('workspace/getCurrentUser', null, { root: true })
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async reportTrackingEvent({ dispatch, rootGetters }, { isPaidAction }) {
    const now = moment().format('YYYY-MM-DD HH:mm:ss')
    const tracking_identifier = isPaidAction
      ? 'reported_paid_action_at'
      : 'reported_active_action_at'
    const data = {
      data: {
        type: 'accounts',
        attributes: {
          tracking: {
            identifier: tracking_identifier,
            active_at: now
          }
        }
      }
    }
    await ApiService.post(
      `teams/${rootGetters['workspace/currentAccountId']}/meta`,
      data
    )
      .then(async () => {
        await dispatch('workspace/getCurrentUser', null, { root: true })
        await dispatch(
          'workspace/setCurrentAccountById',
          rootGetters['workspace/currentAccountId'],
          { root: true }
        )
      })
      .catch((e) => {
        throw new Error(JSON.stringify(e))
      })
  }
}

const getters = {
  isRoleSelected(state) {
    return Object.keys(state.selectedRole).length > 0
  },
  internalRoles(state) {
    return state.allRoles.filter((obj) => !obj.attributes.team_id)
  },
  customRoles(state) {
    return state.allRoles.filter((obj) => obj.attributes.team_id)
  },
  sortAllRoles(state) {
    return _.sortBy(state.allRoles, (obj) => obj.attributes.team_id !== null)
  },
  userHasAccess(state) {
    const accessRoles = [
      'read-team-role',
      'update-team-role',
      'create-team-role'
    ]
    const hasPermission = state?.userAccess?.abilities?.some((ability) =>
      accessRoles.includes(ability)
    )
    return hasPermission
  },
  hasJoinChannelRole(state) {
    const role = 'join-channel-chat'
    const hasPermission = state.userAccess.abilities.some(
      (ability) => (ability = role)
    )
    return hasPermission
  },
  isTeamAdmin(state) {
    const isTeamAdmin = state.userAccess.roles.some(
      (role) => role.attributes.name === 'team_admin'
    )
    return isTeamAdmin
  },
  getPermissionList(state) {
    return _.sortBy(state.permissionList, (obj) => obj.attributes.display_name)
  }
}

export const teams = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
