import i18n from '@/i18n'

const defaultState = () => {
  return {
    showToast: false,
    queue: []
  }
}

const state = defaultState()

const mutations = {
  setToast(state, payload) {
    state.queue = [...state.queue, payload]
  },
  openToast(state) {
    state.showToast = true
  },
  closeToast(state) {
    state.queue = [...state.queue.slice(1)]
    state.showToast = false
  },
  clearErrorQueue(state) {
    state.queue = []
    state.showToast = false
  }
}

const actions = {
  handleToast({ state, commit }, toastData) {
    const isDuplicated = state.queue.find(
      (el) => el.type === toastData.type && el.message === toastData.message
    )
    if (isDuplicated) return
    commit('setToast', toastData)
    commit('openToast')
  },
  success({ dispatch }, payload) {
    const toastData = {
      type: 'success',
      title: i18n.t('shared.toast.success.title'),
      ...payload
    }
    dispatch('handleToast', toastData)
  },
  error({ dispatch }, payload) {
    const toastData = {
      type: 'error',
      title: i18n.t('shared.toast.error.title'),
      ...payload
    }
    dispatch('handleToast', toastData)
  },
  warning({ dispatch }, payload) {
    const toastData = {
      type: 'warning',
      title: i18n.t('shared.toast.warning.title'),
      ...payload
    }
    dispatch('handleToast', toastData)
  },
  info({ dispatch }, payload) {
    const toastData = {
      type: 'info',
      title: i18n.t('shared.toast.info.title'),
      ...payload
    }
    dispatch('handleToast', toastData)
  }
}

export const toast = {
  namespaced: true,
  state,
  actions,
  mutations
}
