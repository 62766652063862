import ApiService from '@/api/new/services/api.service'
import FileSaver from 'file-saver'

const state = {
  invoice: {}
}

const mutations = {
  setInvoice(state, invoice) {
    state.invoice = invoice
  }
}

const actions = {
  async getInvoice({ rootGetters }, invoiceId) {
    const supplierId = rootGetters['supplier/supplierId']
    ApiService.get(`suppliers/${supplierId}/payouts/${invoiceId}/invoice`, {
      responseType: 'arraybuffer',
      headers: {
        Accept: 'application/pdf'
      }
    })
      .then(async (res) => {
        const blob = new Blob([res.data], {
          type: 'application/pdf'
        })
        await FileSaver.saveAs(blob, `${invoiceId}.pdf`)
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  }
}

export const invoice = {
  namespaced: true,
  state,
  actions,
  mutations
}
