import ApiService from '@/api/new/services/api.service'
import i18n from '@/i18n'

const state = {
  wallet: {}
}

const mutations = {
  setWallet(state, wallet) {
    state.wallet = wallet
  }
}

const actions = {
  async getWallet({ commit, rootGetters }) {
    await ApiService.get(
      `teams/${rootGetters['workspace/currentAccountId']}/wallet`
    )
      .then((res) => {
        const wallet = res.data.data
        commit('setWallet', wallet)
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async disableWallet({ dispatch, commit, rootGetters }) {
    await ApiService.post(
      `teams/${rootGetters['workspace/currentAccountId']}/wallet/disable`
    )
      .then(async (res) => {
        const wallet = res.data.data
        await commit('setWallet', wallet)
        dispatch(
          'toast/success',
          { message: i18n.t('customer.toast.success.inactive_wallet') },
          { root: true }
        )
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async updateWallet({ dispatch, commit, rootGetters }, { id, wallet }) {
    const data = {
      data: {
        id: id,
        type: 'account_wallet',
        attributes: wallet
      }
    }
    await ApiService.put(
      `teams/${rootGetters['workspace/currentAccountId']}/wallet`,
      data
    )
      .then((res) => {
        const wallet = res.data.data
        commit('setWallet', wallet)
        dispatch(
          'toast/success',
          {
            title: i18n.t('shared.toast.success.saved_title'),
            message: i18n.t('customer.toast.success.update_wallet')
          },
          { root: true }
        )
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  }
}

const getters = {}

export const wallet = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
