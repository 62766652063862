import ApiEasycatService from '@/api/new/services/api.easycat.service'
import ApiService from '@/api/new/services/api.service'
import i18n from '@/i18n'

const state = {
  showBetaEditor: true,
  showEditorPreview: true,
  currentAiSegment: null,
  currentAiSegmentId: null,
  aiSegmentHistory: [],
  filterInput: null,
  uploadedFile: null,
  segmentStatistics: null,
  failedSegments: null,
  segmentTagMismatch: false,
  repetition: {},
  linkedSegment: null,
  aiMatched: {
    segments: [],
    found: null
  },
  searchPages: {
    current: 1,
    total: 0,
    totalSegments: 0,
    pageSize: 50
  },
  failedPages: {
    current: 1,
    total: 0,
    totalSegments: 0,
    pageSize: 10
  }
}

const mutations = {
  setCurrentAiSegment(state, segment) {
    state.currentAiSegment = segment || null
    state.currentAiSegmentId = segment.id || null
  },
  setAiSegmentHistory(state, segment) {
    state.aiSegmentHistory = segment
  },
  setFilterInput(state, filter) {
    state.filterInput = filter
  },
  setShowBetaEditor(state, show) {
    state.showBetaEditor = show
  },
  setShowEditorPreview(state, show) {
    state.showEditorPreview = show
  },
  setUploadedFile(state, file) {
    state.uploadedFile = file
  },
  setSegmentStatistics(state, statistics) {
    state.segmentStatistics = statistics
  },
  setFailedSegments(state, segments) {
    state.failedSegments = segments
  },
  setAiMatchedSegments(state, matched) {
    state.aiMatched.segments = matched.segments
    state.aiMatched.found = matched.found
  },
  setSearchPages(state, pages) {
    state.searchPages.current = pages.current_page
    state.searchPages.total = pages.last_page
    state.searchPages.totalSegments = pages.total
    state.searchPages.pageSize = pages.per_page
  },
  setFailedPages(state, pages) {
    state.failedPages.current = pages.current_page
    state.failedPages.total = pages.last_page
    state.failedPages.totalSegments = pages.total
    state.failedPages.pageSize = pages.per_page
  },
  setSegmentTagMismatch(state, isMismatch) {
    state.segmentTagMismatch = isMismatch
  },
  setRepetition(state, repetition) {
    state.repetition = repetition
  },
  setCurrentLinkedSegment(state, segment) {
    state.linkedSegment = segment
  }
}

const actions = {
  async getAiFile(
    { dispatch, commit, getters, state },
    { target, currentPage, perPage, failedPage }
  ) {
    await ApiEasycatService.get(
      `suppliers/files/${getters.fileId}/file-segments/index?target_language=${target}`,
      { params: { page: currentPage, perPage } }
    )
      .then(async (res) => {
        const file = res.data.data
        const pages = res.data.meta
        await commit('supplierFile/setFile', file, {
          root: true
        })
        await commit('supplierFile/setFilePages', pages, { root: true })
        await dispatch('getUploadedFile', target)
        if (state.showBetaEditor)
          await dispatch('getFailedSegments', {
            target,
            currentPage: failedPage || state.failedPages.current,
            perPage: 10
          })
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async getFailedSegments(
    { commit, getters },
    { target, currentPage, perPage }
  ) {
    await ApiEasycatService.get(
      `suppliers/files/${getters.fileId}/file-segments/failed?target_language=${target}`,
      { params: { page: currentPage, perPage } }
    )
      .then(async (res) => {
        const segments = res.data.data
        const pages = res.data.meta
        commit('setFailedSegments', segments)
        commit('setFailedPages', pages)
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async getUploadedFile({ commit, getters }, target) {
    await ApiEasycatService.get(
      `suppliers/files/${getters.fileId}/show?target_language=${target}`
    )
      .then(async (res) => {
        const file = res.data.data
        commit('setUploadedFile', file)
        commit('setSegmentStatistics', file.attributes.segment_statistics)
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async updateAllSegmentsStatus({ dispatch, rootState, getters }, payload) {
    const perPage = rootState['supplierFile'].filePages.pageSize
    const data = {
      data: {
        type: 'file_segments',
        attributes: {
          is_confirmed: payload.status,
          target_language: payload.target
        }
      }
    }
    await ApiEasycatService.post(
      `suppliers/files/${getters.fileId}/file-segments/bulk-confirm`,
      data
    )
      .then(async () => {
        dispatch(
          'toast/success',
          {
            message: payload.status
              ? i18n.t('customer.toast.success.confirm_segments')
              : i18n.t('shared.toast.success.uncofirm_segments')
          },
          { root: true }
        )
        await dispatch('getAiFile', {
          target: payload.target,
          currentPage: rootState['supplierFile'].filePages.current,
          perPage
        })
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async updateSegment(
    { state, commit, getters, dispatch, rootState },
    payload
  ) {
    const target =
      rootState['supplierDashboard'].currentTask.attributes.target_language
    const currentPage = rootState['supplierFile'].filePages.current
    const perPage = rootState['supplierFile'].filePages.pageSize
    const data = {
      data: {
        type: 'file_segments',
        attributes: {
          ...payload.attributes
        }
      }
    }
    await ApiEasycatService.put(
      `suppliers/files/${getters.fileId}/file-segments/${payload.id}/update`,
      data
    )
      .then(async () => {
        if (state.filterInput) {
          await dispatch('filterAiFileSegments', state.filterInput)
        } else {
          await dispatch('getAiFile', {
            target,
            currentPage,
            perPage,
            failedPage: payload.nextPage
          })
        }
        await dispatch('getFileSegmentHistory', payload.id)
        await commit('task/setIsReviewSaved', true, { root: true })
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async updateRepetition({ dispatch, getters, rootState }, payload) {
    const target =
      rootState['supplierDashboard'].currentTask.attributes.target_language
    const data = {
      data: {
        type: 'file_segments',
        attributes: {
          ...payload.attributes
        }
      }
    }
    await ApiEasycatService.put(
      `suppliers/files/${getters.fileId}/file-segments/${payload.id}/update`,
      data
    )
      .then(async () => {
        if (payload.attributes.repetition.order > 1)
          await dispatch('getAiFile', {
            target: target,
            currentPage: rootState['supplierFile'].filePages.current,
            perPage: rootState['supplierFile'].filePages.pageSize
          })
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async updateRepetitions({ dispatch, getters, rootState }, payload) {
    const target =
      rootState['supplierDashboard'].currentTask.attributes.target_language
    const data = {
      data: {
        type: 'file_segments',
        attributes: {
          ...payload.attributes
        }
      }
    }
    await ApiEasycatService.put(
      `suppliers/files/${getters.fileId}/file-segments/repetitions-propagate`,
      data
    )
      .then(async () => {
        await dispatch('getAiFile', {
          target: target,
          currentPage: rootState['supplierFile'].filePages.current,
          perPage: rootState['supplierFile'].filePages.pageSize
        })
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async filterAiFileSegments(
    { dispatch, commit, getters, rootState },
    payload
  ) {
    const perPage = rootState['supplierFile'].filePages.pageSize
    const data = {
      data: {
        type: 'files',
        attributes: {
          ...payload
        }
      }
    }
    if (payload.value.length === 0) {
      await commit('setFilterInput', null)
      return await dispatch('getAiFile', {
        target: payload.target_language,
        currentPage: rootState['supplierFile'].filePages.current,
        perPage
      })
    }

    await ApiEasycatService.post(
      `suppliers/files/${getters.fileId}/file-segments/search?perPage=${perPage}&page=${payload.currentPage || 1}`,
      data
    )
      .then(async (res) => {
        const file = res.data.data
        const pages = res.data.meta
        await commit('supplierFile/setFile', file, {
          root: true
        })
        await commit('supplierFile/setFilePages', pages, { root: true })
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async searchAndReplace(
    { dispatch, getters, rootState, rootGetters },
    payload
  ) {
    const target =
      rootState['supplierDashboard'].currentTask.attributes.target_language
    const source =
      rootState['supplierDashboard'].currentTask.attributes.source_language
    const data = {
      data: {
        type: 'files',
        attributes: {
          target_language: target,
          search_value: payload.search,
          replace_value: payload.replace,
          segment_ids: payload.segmentIds
        }
      }
    }
    await ApiEasycatService.post(
      `suppliers/files/${getters.fileId}/supplier-replace-all`,
      data
    )
      .then(async () => {
        await dispatch('filterAiFileSegments', {
          source_language: source,
          target_language: target,
          value: payload.search,
          type: 'target'
        })
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async getFuzzySegments({ commit, getters }, id) {
    await ApiEasycatService.get(
      `suppliers/files/${getters.fileId}/file-segments/${id}/fuzzy`
    )
      .then(async (res) => {
        const segments = res.data.data
        const matchedFound = segments.length > 0 ? true : false
        const obj = {
          found: matchedFound,
          segments
        }
        commit('setAiMatchedSegments', obj)
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async getFileSegmentHistory({ commit, getters, rootState }, segmentId) {
    await ApiEasycatService.get(
      `suppliers/files/${getters.fileId}/file-segments/${segmentId}/history`
    )
      .then(async (res) => {
        const history = res.data.data
        await commit('setAiSegmentHistory', history)
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async searchAiTm({ commit }, payload) {
    const data = {
      data: {
        type: 'translation_memories',
        attributes: {
          source_language: payload.source,
          target_language: payload.target,
          value: payload.value,
          type: payload.type
        }
      }
    }
    await ApiEasycatService.post(
      `suppliers/teams/${payload.teamName}/translation-memories/search?page=${payload.currentPage}`,
      data
    )
      .then(async (res) => {
        const segments = res.data.data
        const pages = res.data.meta
        const matchedFound = segments.length > 0 ? true : false
        const obj = {
          found: matchedFound,
          segments
        }
        commit('setAiMatchedSegments', obj)
        commit('setSearchPages', pages)
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async searchAiTb({ commit }, payload) {
    const data = {
      data: {
        type: 'term_banks',
        attributes: {
          source_language: payload.source,
          target_language: payload.target,
          value: payload.value,
          type: payload.type
        }
      }
    }
    await ApiEasycatService.post(
      `suppliers/teams/${payload.teamName}/term-banks/search?page=${payload.currentPage}`,
      data
    )
      .then(async (res) => {
        const segments = res.data.data
        const pages = res.data.meta
        const matchedFound = segments.length > 0 ? true : false
        const obj = {
          found: matchedFound,
          segments
        }
        commit('setAiMatchedSegments', obj)
        commit('setSearchPages', pages)
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async acceptTranslation({ dispatch, getters }, payload) {
    const data = {
      data: {
        type: 'files',
        attributes: {
          ...payload
        }
      }
    }
    await ApiEasycatService.post(
      `suppliers/files/${getters.fileId}/supplier-accept-translation`,
      data
    )
      .then(async () => {
        await dispatch('getUploadedFile', payload.target_language)
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async declineTranslation({ getters }, payload) {
    const data = {
      data: {
        type: 'files',
        attributes: {
          ...payload
        }
      }
    }
    await ApiEasycatService.post(
      `suppliers/files/${getters.fileId}/supplier-decline-translation`,
      data
    ).catch((err) => {
      throw new Error(JSON.stringify(err))
    })
  },
  async finishTranslation({ rootState, rootGetters }) {
    await ApiService.post(
      `suppliers/${rootGetters['supplier/supplier'].id}/tasks/${rootState['supplierDashboard'].currentTask.id}/complete-translation`
    ).catch((err) => {
      throw new Error(JSON.stringify(err))
    })
  }
}

const getters = {
  isAiTask: (state, getters, rootState, rootGetters) => {
    return (
      rootState['supplierDashboard'].currentTask.attributes.type ===
      'ai_translation'
    )
  },
  fileId: (state, getters, rootState, rootGetters) => {
    return rootState['supplierDashboard'].currentTask.attributes.cattool_id
  },
  translationStatus: (state, getters, rootState, rootGetters) => {
    const target =
      rootState['supplierDashboard'].currentTask.attributes.target_language
    return state.uploadedFile.attributes.target_language_properties[target]
      .steps.translation.status
  },
  currentPageFailedSegments: (state, getters, rootState, rootGetters) => {
    const threshold = state.uploadedFile.attributes.pipeline.qa.threshold
    const failedSegments = rootState['supplierFile'].file.filter(
      (s) => s.attributes.qa && s.attributes.qa.average <= threshold
    )
    return failedSegments
  }
}

export const aiFile = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
