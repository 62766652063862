import ApiSsoService from '@/api/new/services/api.sso.service'
import i18n from '@/i18n'

const state = {
  identityProviders: []
}

const mutations = {
  setIdentityProviders(state, idp) {
    state.identityProviders = idp
  }
}

const actions = {
  async listIdentityProviders({ rootGetters, commit }) {
    await ApiSsoService.get(
      `teams/${rootGetters['workspace/currentAccountId']}/sso`
    )
      .then((res) => {
        commit('setIdentityProviders', res.data.data)
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async addIdentityProvider({ rootGetters, dispatch }, payload) {
    const data = {
      data: {
        type: 'sso_identity_providers',
        attributes: {
          name: payload.name,
          metadata_url: payload.metadata_url
        }
      }
    }
    await ApiSsoService.post(
      `teams/${rootGetters['workspace/currentAccountId']}/sso`,
      data
    )
      .then(async () => {
        await dispatch('listIdentityProviders')
        dispatch(
          'toast/success',
          {
            title: i18n.t('shared.toast.success.title')
          },
          { root: true }
        )
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async updateIdentityProvider({ rootGetters, dispatch }, payload) {
    const attributes = payload.attributes
    const formData = new FormData()
    formData.append('data[type]', 'sso_identity_providers')
    formData.append('data[attributes][name]', attributes.name)
    if (attributes.entity_id) {
      formData.append('data[attributes][entity_id]', attributes.entity_id)
    }
    if (attributes.login_url) {
      formData.append('data[attributes][login_url]', attributes.login_url)
    }
    if (attributes.name_id_format) {
      formData.append(
        'data[attributes][name_id_format]',
        attributes.name_id_format
      )
    }
    if (attributes.relay_state_url) {
      formData.append(
        'data[attributes][relay_state_url]',
        attributes.relay_state_url
      )
    }
    if (attributes.file) {
      formData.append('data[attributes][x509_certificate]', attributes.file[0])
    }

    await ApiSsoService.post(
      `teams/${rootGetters['workspace/currentAccountId']}/sso/${payload.id}/update`,
      formData
    )
      .then(async () => {
        await dispatch('listIdentityProviders')
        dispatch(
          'toast/success',
          {
            title: i18n.t('shared.toast.success.title')
          },
          { root: true }
        )
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async deleteIdentityProvider({ rootGetters, dispatch }, id) {
    await ApiSsoService.delete(
      `teams/${rootGetters['workspace/currentAccountId']}/sso/${id}`
    )
      .then(async () => {
        await dispatch('listIdentityProviders')
        dispatch(
          'toast/success',
          {
            title: i18n.t('shared.toast.success.title')
          },
          { root: true }
        )
      })
      .catch((err) => {
        console.log(err)
        throw new Error(JSON.stringify(err))
      })
  }
}

export const sso = {
  namespaced: true,
  actions,
  mutations,
  state
}
