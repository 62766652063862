import ApiService from '@/api/new/services/api.service'
import { TokenService } from '@/api/new/services/storage.service'
import ApiServiceV2 from '@/api/new/services/v2/api.service'
import i18n from '@/i18n'
import { $cookies } from '@/main'
import { router } from '@/router/index'
import UtilsFunction from '@/utils/index'
import FileSaver from 'file-saver'

const state = {
  allProjects: [],
  target: [],
  pages: {
    perPage: 15,
    current: 0,
    total: 2
  },
  projectId: '',
  folderId: '',
  folderProjects: [],
  renameFolder: {
    id: '',
    name: ''
  },
  currentFolder: {},
  currentUser: {},
  currentUserAccounts: [],
  projectFilters: {},
  currentAccount: {},
  mfa: {},
  teamResults: [],
  verifiedMfa: false,
  engStartTime: 0,
  selectedConnection: null,
  shouldRefreshPage: false
}

const mutations = {
  setRefreshPage(state, bool) {
    state.shouldRefreshPage = bool
  },
  setAllProjects(state, projects) {
    state.allProjects = projects
  },
  setPages(state, pages) {
    state.pages.current = pages.current_page
    state.pages.total = pages.last_page
    state.pages.perPage = pages.per_page
  },
  setProjectId(state, id) {
    state.projectId = id
  },
  setFolderId(state, id) {
    state.folderId = id
  },
  setFolderProjects(state, projects) {
    state.folderProjects = projects
  },
  setCurrentFolder(state, folder) {
    state.currentFolder = folder
  },
  setCurrentUser(state, user) {
    state.currentUser = user
  },
  setIncludedAccounts(state, includedAccounts) {
    state.currentUserAccounts = includedAccounts
  },
  setRenameFolder(state, { name, id }) {
    state.renameFolder.id = id
    state.renameFolder.name = name
  },
  setFilterStructure(state, filters) {
    const formatTitle = (type) => {
      if (type == 'customer_id') return 'User'
      const capitalize = UtilsFunction.capitalizeFirstLetter(type)
      return capitalize.replace(/_/g, ' ')
    }
    const keys = Object.keys(filters)
    state.projectFilters = keys.map((key) => {
      const data = filters[key]
      return {
        type: key,
        title: formatTitle(key),
        value: '',
        data
      }
    })
  },
  updateProjectFilter(state, { value, type }) {
    state.projectFilters.forEach((el) => {
      if (!type) {
        el.value = value
      } else if (el.type === type) {
        el.value = value
      }
    })
  },
  setCurrentAccount(state, account) {
    state.currentAccount = account
  },
  setMfa(state, mfaData) {
    state.mfa = mfaData
  },
  clearMfa(state) {
    state.mfa = {}
  },
  setTeamResults(state, results) {
    state.teamResults = results
  },
  setMfaStatus(state, verified) {
    state.verifiedMfa = verified
  },
  setEngStartTime(state, time) {
    state.engStartTime = time
  },
  setSelectedConnection(state, connection) {
    state.selectedConnection = connection
  }
}

const actions = {
  async getStateCurrentUser({ dispatch, state }) {
    if (!Object.keys(state.currentUser)) await dispatch('getCurrentUser')
  },
  async getCurrentUser({ commit }) {
    await ApiService.get('user')
      .then(async (res) => {
        const user = res.data.data
        await commit('setCurrentUser', user)
        if (
          !Object.prototype.hasOwnProperty.call(
            user.attributes,
            'is_local_admin'
          ) &&
          res.data.included
        ) {
          await commit('setIncludedAccounts', res.data.included)
        }
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async searchTeams({ commit }, search) {
    const data = {
      data: {
        attributes: {
          query: search
        }
      }
    }
    await ApiService.post(`teams`, data)
      .then(async (res) => {
        const results = res.data.data
        commit('setTeamResults', results)
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async getFolder({ commit, getters }, folderId) {
    await ApiService.get(
      `teams/${getters.currentAccountId}/folders/${folderId}`
    )
      .then(async (res) => {
        const currentFolder = res.data.data
        await commit('setCurrentFolder', currentFolder)
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async addProjectToFolder({ dispatch, state, getters }, payload) {
    let body = {
      data: {
        type: 'project-folder',
        attributes: {
          name: payload.folderName,
          included_project_ids: [payload.projectId]
        }
      }
    }
    await ApiService.patch(
      `teams/${getters.currentAccountId}/folders/${payload.folderId}`,
      body
    )
      .then(async () => {
        await dispatch('folder/getFolders', null, { root: true })
        await dispatch('getProjects', {
          currentPage: state.pages.current,
          search: null
        })
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async renameFolder({ dispatch, state, getters }, payload) {
    let body = {
      data: {
        type: 'project-folder',
        attributes: {
          name: payload.folderName
        }
      }
    }

    await ApiService.patch(
      `teams/${getters.currentAccountId}/folders/${payload.folderId}`,
      body
    )
      .then(async () => {
        await dispatch('folder/getFolders', null, { root: true })
        await dispatch('getProjects', {
          currentPage: state.pages.current,
          search: null
        })
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async updateFolderName({ dispatch, commit, state, getters }, params) {
    let body = {
      data: {
        type: 'project-folder',
        attributes: {
          name: params.name
        }
      }
    }

    await ApiService.patch(
      `teams/${getters.currentAccountId}/folders/${params.id}`,
      body
    )
      .then(async () => {
        await dispatch('folder/getFolders', null, { root: true })
        await dispatch('getProjects', {
          currentPage: state.pages.current,
          search: null
        })
        await commit('modals/toggleRenameModal', false, { root: true })
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async deleteFolder({ dispatch, state, getters }, folderId) {
    dispatch('loading/startLoader', {}, { root: true })
    await ApiService.delete(
      `teams/${getters.currentAccountId}/folders/${folderId}`
    )
      .then(async () => {
        await dispatch('folder/getFolders', null, { root: true })
        await dispatch('getProjects', {
          currentPage: state.pages.current,
          search: null
        })
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
      .finally(() => {
        dispatch('loading/stopLoader', {}, { root: true })
      })
  },
  async getProjectFilters({ commit, getters, rootState }) {
    const account = rootState['account'].account
    const filters = account.attributes.project_filters
    await commit('setFilterStructure', filters)
  },
  async createNewFolder({ dispatch, getters }, { folderName }) {
    const data = {
      data: {
        type: 'project-folder',
        attributes: {
          name: folderName
        }
      }
    }
    await ApiService.post(`teams/${getters.currentAccountId}/folders`, data)
      .then(async () => {
        dispatch(
          'toast/success',
          {
            message: i18n.t('customer.toast.success.folder_create', {
              folder: folderName
            })
          },
          { root: true }
        )
        await dispatch('folder/getFolders', null, { root: true })
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async getProjects(
    { commit, state, getters },
    { search, currentPage, perPage, folderId }
  ) {
    const params = new URLSearchParams()
    if (Object.values(state.projectFilters).length > 0) {
      state.projectFilters.forEach((filter) => {
        params.append(`filters[${filter.type}][]`, filter.value)
      })
    }
    if (search) {
      params.append(`filters[query]`, search)
    }
    params.append('page', currentPage)
    if (perPage) {
      params.append('perPage', perPage)
    }
    if (folderId) {
      params.append('filters[folder_id][]', folderId)
    }

    await ApiServiceV2.get(`teams/${getters.currentAccountId}/projects`, {
      params
    })
      .then(async (res) => {
        const projects = res.data.data
        const pages = res.data.meta
        if (folderId) {
          commit('setFolderProjects', projects)
        }
        commit('setAllProjects', projects)
        commit('setPages', pages)
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async setCurrentAccountById({ state, commit, getters }, id) {
    const accountsList = getters.isAdmin
      ? state.teamResults
      : state.currentUserAccounts
    const currentAccount =
      accountsList.length > 0 &&
      accountsList.find(
        (account) => account && account.attributes.team_identifier === id
      )
    if (currentAccount) {
      await commit('setCurrentAccount', currentAccount.attributes)
    } else {
      await commit('setCurrentAccount', accountsList[0].attributes)
    }
  },
  async enable2fa({ dispatch, commit }, payload) {
    const data = {
      data: {
        type: 'mfa_2fa',
        attributes: {
          ...payload
        }
      }
    }
    await ApiService.post(`mfa/2fa/enable`, data)
      .then((res) => {
        commit('setMfa', res.data.data.attributes)
        dispatch('getCurrentUser')
        commit('modals/toggleMfaModal', {}, { root: true })
        dispatch(
          'toast/success',
          { message: i18n.t('shared.toast.success.mfa_enable') },
          { root: true }
        )
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async disable2fa({ dispatch, commit }, payload) {
    const data = {
      data: {
        type: 'mfa_2fa',
        attributes: {
          ...payload
        }
      }
    }
    await ApiService.post(`mfa/2fa/disable`, data)
      .then(() => {
        commit('clearMfa')
        dispatch('getCurrentUser')
        dispatch(
          'toast/success',
          { message: i18n.t('shared.toast.success.mfa_disabled') },
          { root: true }
        )
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async verify2fa({ dispatch, commit }, payload) {
    const data = {
      data: {
        type: 'mfa_2fa',
        attributes: {
          ...payload
        }
      }
    }
    await ApiService.post(`mfa/2fa/verify`, data)
      .then(async (res) => {
        await TokenService.saveRefreshToken(res.data.refresh_token)
        commit('modals/setVerifyMfaModal', false, { root: true })
        commit('setMfaStatus', true)
        await dispatch('getCurrentUser')
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async prepareGdprDownload({ state, dispatch, getters, rootState }, options) {
    const data = {
      data: {
        type: 'customer',
        attributes: {
          gdpr: options
        }
      }
    }
    const customerId = getters.isAdmin
      ? rootState['teams'].selectedTeamUser.id
      : state.currentUser.id

    await ApiService.post(
      `teams/${getters.currentAccountId}/customers/${customerId}/gdpr/prepare`,
      data
    )
      .then(async () => {
        await dispatch('workspace/getCurrentUser', null, { root: true })
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async downloadGdprZip({ state, dispatch, getters, rootState }) {
    const customer = getters.isAdmin
      ? rootState['teams'].selectedTeamUser
      : state.currentUser
    const downloadPath = customer.attributes.gdpr.path
    if (!downloadPath) {
      dispatch('toast/error', {
        message: i18n.t('shared.toast.error.download_gdpr')
      })
      return
    }
    const requestData = {
      method: 'post',
      url: `/laas/api/v1/teams/${getters.currentAccountId}/customers/${customer.id}/gdpr/download`,
      data: {},
      responseType: 'blob'
    }
    await ApiService.customRequest(requestData)
      .then((res) => {
        let fileSource = window.URL.createObjectURL(
          new Blob([res.data], { type: 'application/zip' })
        )
        FileSaver.saveAs(fileSource, `${state.currentUser.id}_gdpr_data.zip`)
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async requestCustomerDeletion({ state, dispatch, getters }, options) {
    const data = {
      data: {
        type: 'customer',
        attributes: {
          gdpr: options
        }
      }
    }
    await ApiService.post(
      `teams/${getters.currentAccountId}/customers/${state.currentUser.id}/gdpr/request-delete`,
      data
    )
      .then(() => {
        dispatch(
          'toast/success',
          { message: i18n.t('shared.toast.success.requesst_delete') },
          { root: true }
        )
        dispatch('workspace/getCurrentUser', {}, { root: true })
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async deleteCustomerAccount({ state, getters, rootState }) {
    const customerId = getters.isAdmin
      ? rootState['teams'].selectedTeamUser.id
      : state.currentUser.id
    await ApiService.delete(
      `teams/${getters.currentAccountId}/customers/${customerId}/gdpr`
    )
      .then(() => {
        router.push({ name: 'BaseLogin' })
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async updateFirstSteps({ state, getters, rootState, dispatch }, payload) {
    const data = {
      data: {
        type: 'customer',
        attributes: {
          ...payload
        }
      }
    }
    await ApiService.post(`first-steps`, data)
      .then(() => {
        dispatch('getCurrentUser')
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  }
}

const getters = {
  hasBetaContentGenerationFlag(state) {
    if (!state.currentAccount.features) {
      return false
    }
    return state.currentAccount.features.content_generation === 'v2'
  },
  isAdmin(state) {
    return !!state?.currentUser?.attributes?.is_local_admin
  },
  currentNames(state, getters) {
    const accountsList = getters.isAdmin
      ? state.teamResults
      : state.currentUserAccounts
    return accountsList.map((account) => account.attributes.company_name)
  },
  currentTeams(state, getters) {
    const accountsList = getters.isAdmin
      ? state.teamResults
      : state.currentUserAccounts
    return accountsList.map((account) => account.attributes.team_identifier)
  },
  currentAccountId(state, getters) {
    const accountsList = getters.isAdmin
      ? state.teamResults
      : state.currentUserAccounts
    const accountId =
      state?.currentAccount?.team_identifier ||
      accountsList[0]?.attributes.team_identifier ||
      $cookies.get('team_identifier')
    return accountId
  },
  isPendingDeletion: (state, getters, rootState) => {
    return getters.isAdmin
      ? !!rootState['teams'].selectedTeamUser.attributes &&
          !!rootState['teams'].selectedTeamUser.attributes.gdpr
            .requested_deletion_at
      : !!state.currentUser.attributes.gdpr.requested_deletion_at
  },
  isValidAccountId: (state, getters) => (accountId) => {
    let accountsList = []
    accountsList = getters.isAdmin
      ? state.teamResults
      : state.currentUserAccounts
    return accountsList.some(
      (account) => account.attributes.team_identifier === accountId
    )
  },
  isNewUserOfExistingAccount(state) {
    return state.currentUser.relationships.accounts.length === 0
  },
  isTrackedFreemiumUser(state) {
    return state?.currentAccount?.tracking?.reported_active_action_at !== null
  },
  isTrackedPaidUser(state) {
    return state?.currentAccount?.tracking?.reported_paid_action_at !== null
  },
  fullUserName: () => (user) => {
    return `${user.first_name} ${user.last_name}`
  }
}

export const workspace = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
