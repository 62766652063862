import ApiService from '@/api/new/services/api.service'
import ApiSsoService from '@/api/new/services/api.sso.service'

const state = {
  invitation: {},
  supplier: {}
}

const mutations = {
  setInvitation(state, invitation) {
    state.invitation = invitation
  },
  setSupplier(state, supplier) {
    state.supplier = supplier
  }
}

const actions = {
  async getInvitation({ commit }, invitationId) {
    await ApiSsoService.publicGet(`suppliers/invitations/${invitationId}`)
      .then(async (res) => {
        const invitation = res.data.data
        await commit('setInvitation', invitation)
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async acceptInvitation({ commit }, supplier) {
    const data = {
      data: {
        type: 'supplier',
        attributes: { ...supplier.attributes }
      }
    }
    await ApiSsoService.publicPost(
      `suppliers/invitations/${supplier.invitationId}/accept`,
      data
    )
      .then((res) => {
        const supplier = res.data.data
        commit('setSupplier', supplier)
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async connectToStripe({ dispatch }, supplier) {
    const data = {
      data: {
        type: 'supplier',
        id: supplier.id,
        attributes: {
          code: supplier.stripeCode
        }
      }
    }
    await ApiService.post(`suppliers/${supplier.id}/connect`, data)
      .then()
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  }
}

export const supplierOnboarding = {
  namespaced: true,
  state,
  actions,
  mutations
}
