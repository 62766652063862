import ApiService from '@/api/new/services/api.service'
import FileSaver from 'file-saver'
import moment from 'moment'
import { groupBy } from 'underscore'

const state = {
  channels: {},
  accounts: [],
  currentAccount: {},
  currentChannels: {},
  allContacts: {},
  currentConversation: {},
  messages: [],
  message: {},
  savedItems: [],
  unreadMessages: [],
  loadingChannels: false
}

const mutations = {
  setChannels(state, channels) {
    state.channels = channels
  },
  setLoadingChannels(state, loading) {
    state.loadingChannels = loading
  },
  setAccounts(state, accounts) {
    state.accounts = accounts
  },
  setCurrentConversation(state, conversation) {
    state.currentConversation = conversation
  },
  setAllContacts(state, contacts) {
    state.allContacts = contacts
  },
  setMessages(state, messages) {
    state.messages = messages
  },
  removeMessage(state, message) {
    state.messages = state.messages.filter((m) => m.id !== message.id)
  },
  updateMessage(state, message) {
    state.messages = [
      ...state.messages.filter((m) => m.id !== message.id),
      message
    ]
  },
  setSavedItems(state, items) {
    state.savedItems = items
  },
  addMessage(state, message) {
    state.messages = [...state.messages, message]
  },
  setCurrentChannels(state, channels) {
    state.currentChannels = channels
  },
  setUnreadMessages(state, items) {
    state.unreadMessages = items
  },
  readCurrentConversation(state) {
    const count = state.currentConversation.attributes.unread_messages
    state.currentConversation.attributes.unread_messages = 0
    state.currentAccount.unread_messages -= count
  },
  setCurrentAccount(state, account) {
    state.currentAccount = account
  }
}

const actions = {
  async getChannels({ state, dispatch, commit, rootGetters }) {
    commit('setLoadingChannels', true)
    await ApiService.get(
      `suppliers/${rootGetters['supplier/supplierId']}/chat/channels`
    )
      .then(async (res) => {
        const accounts = Object.values(
          res.data.data.attributes.available_direct_messages.accounts
        )
        const savedItems = res.data.data.attributes.saved_items
        commit('setChannels', res.data.data.attributes.available_channels)
        commit('setAccounts', accounts)
        commit('setSavedItems', savedItems)
        commit('setUnreadMessages', res.data.data.attributes.unread_messages)
        const contacts = [
          ...Object.values([
            ...Object.values(
              res.data.data.attributes.available_direct_messages.accounts
            ).reduce((result, account) => {
              return [...result, ...Object.values(account.members)]
            }, [])
          ])
        ]
        commit('setAllContacts', contacts)
        commit('setLoadingChannels', false)
        if (state.currentAccount.team_identifier) {
          dispatch(
            'setCurrentAccountById',
            state.currentAccount.team_identifier
          )
        }
      })
      .catch((err) => {
        commit('setLoadingChannels', false)
        throw new Error(JSON.stringify(err))
      })
  },
  async createDirectMessage(
    { dispatch, commit, getters, rootGetters },
    receiver
  ) {
    const existingConversation = getters.directMessages.find((dm) => {
      const members = [
        ...dm.attributes.members.customers,
        ...dm.attributes.members.suppliers
      ]
      if (members.some((member) => member.id === receiver.id)) {
        return true
      } else {
        return false
      }
    })
    if (existingConversation) {
      dispatch('setCurrentConversationById', existingConversation.id)
      return
    }
    const data = {
      data: {
        type: 'chat_channels',
        attributes: {
          type: 'direct_message',
          members: {
            ...(receiver.type === 'customers' ? { customers: [receiver] } : {}),
            ...(receiver.type === 'suppliers' ? { suppliers: [receiver] } : {})
          },
          team_identifier: state.currentAccount.team_identifier
        }
      }
    }
    await ApiService.post(
      `suppliers/${rootGetters['supplier/supplierId']}/chat/channels`,
      data
    )
      .then(async (res) => {
        dispatch('getChannels')
        commit('setCurrentConversation', res.data.data)
        dispatch('setCurrentAccountById', state.currentAccount.team_identifier)
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async sendMessage(
    { state, dispatch, rootGetters },
    { message, files, isProject }
  ) {
    const formData = new FormData()
    files.forEach((file, index) => {
      formData.append(`data[attributes][files][${index}]`, file)
    })
    formData.append('data[type]', 'chat_messages')
    formData.append('data[attributes][body]', message)
    formData.append('data[attributes][status]', 'sent')

    await ApiService.post(
      `suppliers/${rootGetters['supplier/supplierId']}/chat/channels/${state.currentConversation.id}/messages`,
      formData
    )
      .then(async () => {
        if (isProject) {
          await dispatch('showAllMessages')
        }
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async updateMessage({ commit, rootGetters }, { channelId, messageId }) {
    const data = {
      data: {
        type: 'chat_messages',
        attributes: {
          body: 'test message',
          status: 'draft'
        }
      }
    }
    await ApiService.put(
      `suppliers/${rootGetters['supplier/supplierId']}/chat/channels/${channelId}/messages/${messageId}`,
      data
    )
      .then(async (res) => {
        const message = res.data.data
        commit('updateMessage', message)
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async downloadMessageFile(
    { state, rootGetters },
    { messageId, fileId, filename, channelId }
  ) {
    const conversationId = channelId ? channelId : state.currentConversation.id
    const requestData = {
      method: 'get',
      url: `/laas/api/v1/suppliers/${rootGetters['supplier/supplierId']}/chat/channels/${conversationId}/messages/${messageId}/files/${fileId}`,
      data: {},
      responseType: 'blob'
    }

    await ApiService.customRequest(requestData)
      .then((res) => {
        let fileSource = window.URL.createObjectURL(new Blob([res.data]))
        FileSaver.saveAs(fileSource, filename)
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async showAllMessages({ state, commit, rootGetters }) {
    await ApiService.get(
      `suppliers/${rootGetters['supplier/supplierId']}/chat/channels/${state.currentConversation.id}/messages/`
    )
      .then(async (res) => {
        const messages = res.data.data
        const sortMessages = messages.sort(
          (a, b) =>
            moment(a.attributes.created_at).valueOf() -
            moment(b.attributes.created_at).valueOf()
        )
        commit('setMessages', sortMessages)
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async deleteMessage({ state, rootGetters }, id) {
    await ApiService.delete(
      `suppliers/${rootGetters['supplier/supplierId']}/chat/channels/${state.currentConversation.id}/messages/${id}`
    )
      .then()
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async hideConversation({ state, dispatch, commit, rootGetters }, id) {
    await ApiService.post(
      `suppliers/${rootGetters['supplier/supplierId']}/chat/channels/${id}/hide`
    )
      .then(() => {
        dispatch('getChannels')
        if (
          Object.keys(state.currentConversation).length > 0 &&
          id === state.currentConversation.id
        ) {
          commit('setCurrentConversation', {})
        }
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async unhideConversation({ dispatch, rootGetters }, id) {
    await ApiService.post(
      `suppliers/${rootGetters['supplier/supplierId']}/chat/channels/${id}/unhide`
    )
      .then(async () => {
        await dispatch('getChannels')
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async recoverConversation({ dispatch, rootGetters, getters }, id) {
    await ApiService.post(
      `suppliers/${rootGetters['supplier/supplierId']}/chat/channels/${id}/unhide`
    )
      .then(async () => {
        await dispatch('getChannels')
        const conversation = getters.allConversations.find(
          (conversation) => conversation.id === id
        )
        if (conversation) {
          dispatch('setCurrentConversationById', conversation.id)
        }
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async deleteConversation({ state, dispatch, commit, rootGetters }, id) {
    await ApiService.delete(
      `suppliers/${rootGetters['supplier/supplierId']}/chat/channels/${id}`
    )
      .then(async () => {
        if (id === state.currentConversation.id) {
          await commit('setCurrentConversation', {})
        }
        dispatch('setCurrentAccountById', state.currentAccount.team_identifier)
        await dispatch('getChannels')
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async leaveConversation({ state, commit, dispatch, rootGetters }, id) {
    await ApiService.post(
      `suppliers/${rootGetters['supplier/supplierId']}/chat/channels/${id}/leave`
    )
      .then(async () => {
        await commit('setCurrentConversation', {})
        await dispatch('getChannels')
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async setCurrentConversationById({ dispatch, commit, getters }, id) {
    const conversation = getters.getConversationWithId(id)
    if (conversation) {
      commit('setMessages', [])
      await commit('setCurrentConversation', conversation)
      await dispatch('showAllMessages')
      await dispatch('readCurrentConversation', conversation.id)
    }
  },
  async getProjectConversation(
    { state, dispatch, commit, rootState },
    { task, projectId }
  ) {
    if (Object.keys(state.channels).length === 0) {
      await dispatch('getChannels')
    }
    await dispatch('supplier/getStateSupplierClients', null, { root: true })
    const account = rootState['supplier'].supplierClients.find(
      (a) => a.attributes.company_name === task.attributes.company_name
    )
    if (account) {
      const projectMessages = Object.values(
        state.channels.accounts[account.id].channels.project_message
      )
      const conversation = projectMessages.find(
        (c) => c.attributes.description === projectId
      )
      if (conversation) {
        await commit('setCurrentConversation', conversation)
        await dispatch('showAllMessages')
      } else {
        await commit('setCurrentConversation', {})
      }
    } else {
      await commit('setCurrentConversation', {})
    }
  },
  async readCurrentConversation({ dispatch, commit, rootGetters }, channelId) {
    await ApiService.post(
      `suppliers/${rootGetters['supplier/supplierId']}/chat/channels/${channelId}/read`
    )
      .then(async () => {
        commit('readCurrentConversation')
        dispatch('getChannels')
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async addMessage({ commit }, message) {
    commit('addMessage', message)
  },
  async increaseUnread({ state, commit }, channelId) {
    const channel = Object.values(state.currentChannels.channels.channel).find(
      (channel) => channel.id === channelId
    )
    const dm = Object.values(
      state.currentChannels.channels.direct_message
    ).find((dm) => dm.id === channelId)
    const groupDm = Object.values(
      state.currentChannels.channels.group_direct_message
    ).find((groupDm) => groupDm.id === channelId)
    const projectMessages = Object.values(state.channels.project_message).find(
      (project) => project.id === channelId
    )

    if (channel || dm || groupDm || projectMessages) {
      const currentChannels = {
        ...state.currentChannels,
        channels: {
          channel: {
            ...state.currentChannels.channels.channel,
            ...(channel
              ? {
                  [channelId]: {
                    ...channel,
                    attributes: {
                      ...channel.attributes,
                      unread_messages: channel.attributes.unread_messages + 1
                    }
                  }
                }
              : {})
          },
          direct_message: {
            ...state.currentChannels.channels.direct_message,
            ...(dm
              ? {
                  [channelId]: {
                    ...dm,
                    attributes: {
                      ...dm.attributes,
                      unread_messages: dm.attributes.unread_messages + 1
                    }
                  }
                }
              : {})
          },
          group_direct_message: {
            ...state.currentChannels.channels.group_direct_message,
            ...(groupDm
              ? {
                  [channelId]: {
                    ...groupDm,
                    attributes: {
                      ...groupDm.attributes,
                      unread_messages: groupDm.attributes.unread_messages + 1
                    }
                  }
                }
              : {})
          },
          project_message: {
            ...state.channels.project_message,
            ...(projectMessages
              ? {
                  [channelId]: {
                    ...projectMessages,
                    attributes: {
                      ...projectMessages.attributes,
                      unread_messages:
                        projectMessages.attributes.unread_messages + 1
                    }
                  }
                }
              : {})
          }
        }
      }
      commit('setCurrentChannels', currentChannels)
    }
  },
  async saveMessage({ state, dispatch, rootGetters }, messageId) {
    await ApiService.post(
      `suppliers/${rootGetters['supplier/supplierId']}/chat/channels/${state.currentConversation.id}/messages/${messageId}/save`
    )
      .then(async () => {
        await dispatch('getChannels')
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async unsaveMessage({ dispatch, rootGetters }, message) {
    await ApiService.delete(
      `suppliers/${rootGetters['supplier/supplierId']}/chat/channels/${message.attributes.channel_id}/messages/${message.id}/save`
    )
      .then(async () => {
        await dispatch('getChannels')
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async setCurrentAccountById({ state, commit }, id) {
    await commit('setCurrentAccount', {})
    await commit('setCurrentChannels', {})
    if (id) {
      const account = await state.accounts.find(
        (account) => account.team_identifier === id
      )
      const currentChannels = await Object.values(state.channels.accounts).find(
        (account) => account.team_identifier === id
      )
      if (account) {
        await commit('setCurrentAccount', account)
      }
      if (currentChannels) {
        await commit('setCurrentChannels', currentChannels)
      }
    } else {
      commit('setCurrentAccount', {})
      commit('setCurrentChannels', {})
    }
  },
  async createProjectChannelSupplier(
    { dispatch, commit, rootGetters },
    payload
  ) {
    const data = {
      data: {
        type: 'chat_channels',
        attributes: {
          type: 'project_message',
          team_identifier: payload.teamName,
          name: payload.name,
          display_name: payload.name,
          description: payload.description,
          members: {
            suppliers: payload.suppliers,
            customers: payload.customers
          }
        }
      }
    }
    await ApiService.post(
      `suppliers/${rootGetters['supplier/supplierId']}/chat/channels/project-message`,
      data
    )
      .then(async (res) => {
        dispatch('getChannels')
        commit('setCurrentConversation', res.data.data)
        commit('setMessages', [])
        await dispatch('showAllMessages')
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  }
}

const getters = {
  channels(state) {
    if (!state.currentChannels || !state.currentChannels.channels) {
      return []
    }
    return !state.currentChannels.channels.channel
      ? []
      : Object.values(state.currentChannels.channels.channel)
  },
  conversationMembers: () => (conversation) => {
    return [
      ...conversation.attributes.members.customers,
      ...conversation.attributes.members.suppliers
    ]
  },
  contacts(state) {
    return Object.values(state.currentAccount.members)
  },
  allChannels(state) {
    // gets channels from all workspaces combined
    if (!state.channels || !state.channels.accounts) {
      return []
    }
    return Object.values(state.channels.accounts).reduce((result, account) => {
      if (account.channels.channel) {
        return [...result, ...Object.values(account.channels.channel)]
      } else {
        return result
      }
    }, [])
  },
  allDirectMessages(state) {
    // gets dms from all workspaces combined
    if (!state.channels || !state.channels.accounts) {
      return []
    }
    return Object.values(state.channels.accounts).reduce((result, account) => {
      if (account.channels.direct_message) {
        return [...result, ...Object.values(account.channels.direct_message)]
      } else {
        return result
      }
    }, [])
  },
  allGroupMessages(state) {
    // gets group messages from all workspaces combined
    if (!state.channels || !state.channels.accounts) {
      return []
    }
    return Object.values(state.channels.accounts).reduce((result, account) => {
      if (account.channels.group_direct_message) {
        return [
          ...result,
          ...Object.values(account.channels.group_direct_message)
        ]
      } else {
        return result
      }
    }, [])
  },
  directMessages(state, getters) {
    if (!state.currentChannels || !state.currentChannels.channels) {
      return []
    }
    return !state.currentChannels.channels.direct_message
      ? []
      : Object.values(state.currentChannels.channels.direct_message).filter(
          (directMessage) =>
            getters.conversationMembers(directMessage).length > 1
        )
  },
  groupMessages(state) {
    if (!state.currentChannels || !state.currentChannels.channels) {
      return []
    }
    return !state.currentChannels.channels.group_direct_message
      ? []
      : Object.values(state.currentChannels.channels.group_direct_message)
  },
  allProjectMessages(state) {
    if (!state.channels || !state.channels.accounts) {
      return []
    }
    return Object.values(state.channels.accounts).reduce((result, account) => {
      if (account.channels.project_message) {
        return [...result, ...Object.values(account.channels.project_message)]
      } else {
        return result
      }
    }, [])
  },
  projectMessages(state) {
    if (!state.currentChannels || !state.currentChannels.channels) {
      return []
    }
    return !state.currentChannels.channels.project_message
      ? []
      : Object.values(state.currentChannels.channels.project_message)
  },
  allConversations(state, getters) {
    return [
      ...getters.channels,
      ...getters.directMessages,
      ...getters.groupMessages,
      ...getters.projectMessages
    ]
  },
  allSearchableChannels(state, getters) {
    return [...getters.allChannels, ...getters.allProjectMessages]
  },
  allWorkspacesConversations(state, getters) {
    return [
      ...getters.allChannels,
      ...getters.allDirectMessages,
      ...getters.allGroupMessages,
      ...getters.allProjectMessages
    ]
  },
  getMessageReceiversFromMemberList:
    (state, getters, rootState) => (messageMembers) => {
      if (!messageMembers) {
        return []
      }
      const customers = messageMembers.customers.map((c) => {
        return {
          ...c,
          type: 'customers'
        }
      })
      const suppliers = messageMembers.suppliers.map((c) => {
        return {
          ...c,
          type: 'suppliers'
        }
      })
      const members = [...customers, ...suppliers]
      return members.filter(
        (member) => member.id !== rootState['workspace'].currentUser.id
      )
    },
  currentConversationOwner: (state) => {
    const members = [
      ...state.currentConversation.attributes.members.customers,
      ...state.currentConversation.attributes.members.suppliers
    ]
    return members.find(
      (member) =>
        member.attributes['settings'] && member.attributes['settings'].is_owner
    )
  },
  currentChatUser(state, getters, rootState, rootGetters) {
    return rootGetters['supplier/supplier']
  },
  groupMessagesByDate(state) {
    const date = (date) =>
      moment(date.attributes.created_at).utc().format('dddd, MMMM Do YYYY')
    return groupBy(state.messages, date)
  },
  groupUnreadMessagesById(state) {
    const unreadMessages = state.unreadMessages
      .filter((item) => item.attributes.type !== 'system')
      .reverse()
    return groupBy(unreadMessages, (item) => item.attributes.channel_id)
  },
  savedItems(state) {
    return state.savedItems
  },
  getConversationWithId: (state, getters) => (id) => {
    const conversation = getters.allWorkspacesConversations.find(
      (conversation) => {
        return conversation.id === id
      }
    )
    return conversation
  },
  getConversationNameWithId: (state, getters) => (id) => {
    const conversation = getters.getConversationWithId(id)
    if (conversation) {
      const receivers = getters
        .getMessageReceiversFromMemberList(conversation.attributes.members)
        .map(
          (member) =>
            `${member.attributes.first_name} ${member.attributes.last_name}`
        )
        .join(', ')
      return conversation.attributes.type === 'channel'
        ? conversation.attributes.display_name
        : receivers
    } else {
      return ''
    }
  },
  isMessageSaved: (state) => (messageId) => {
    return !!state.savedItems.find((item) => item.id === messageId)
  },
  supplierTotalUnread(state, getters) {
    const total = getters.allWorkspacesConversations.reduce(
      (a, b) => (a += b.attributes.unread_messages),
      0
    )
    return total
  },
  visibleChannels(state, getters) {
    const visibleChannels = getters.channels.filter(
      (channel) =>
        !channel.attributes.hidden || channel.attributes.unread_messages > 0
    )
    return visibleChannels
  },
  visibleDirectMessages(state, getters) {
    const visibleDms = getters.directMessages.filter(
      (dm) => !dm.attributes.hidden || dm.attributes.unread_messages > 0
    )
    return visibleDms
  },
  visibleGroupMessages(state, getters) {
    const visibleGroupMessages = getters.groupMessages.filter(
      (groupDms) =>
        !groupDms.attributes.hidden || groupDms.attributes.unread_messages > 0
    )
    return visibleGroupMessages
  },
  visibleProjectMessages(state, getters) {
    const visibleProjectMessages = getters.projectMessages.filter(
      (projectMessage) =>
        !projectMessage.attributes.hidden ||
        projectMessage.attributes.unread_messages > 0
    )
    return visibleProjectMessages
  }
}

export const supplierChat = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
