import ApiService from '@/api/new/services/api.service'
import i18n from '@/i18n'
import { $cookies } from '@/main'

const state = {
  services: []
}

const mutations = {
  setServices(state, services) {
    state.services = services
  }
}

const actions = {
  async getStateServices({ commit, dispatch, state, rootGetters }) {
    if (state.services.length === 0) await dispatch('getServices')
  },
  async getServices({ commit, rootGetters }) {
    const supplierId = rootGetters['supplier/supplierId']
    await ApiService.get(`suppliers/${supplierId}/services`)
      .then((res) => {
        commit('setServices', res.data.data)
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async createNewService({ dispatch, rootState, rootGetters }, service) {
    const isAdmin = rootGetters['workspace/isAdmin']
    const supplierId = isAdmin
      ? $cookies.get('supplierId')
      : rootState['workspace'].currentUser.id
    const data = {
      data: {
        type: 'service',
        attributes: {
          ...service
        }
      }
    }
    await ApiService.post(`suppliers/${supplierId}/services`, data)
      .then(async () => {
        await dispatch('getServices')
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async deleteService({ dispatch, commit, rootGetters }, serviceId) {
    const supplierId = rootGetters['supplier/supplierId']
    await ApiService.delete(`suppliers/${supplierId}/services/${serviceId}`)
      .then(async (res) => {
        commit('setServices', res.data.data)
        dispatch(
          'toast/success',
          { message: i18n.t('supplier.toast.success.delete_service') },
          { root: true }
        )
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async updateServicePrice(
    { dispatch, rootState, rootGetters },
    { serviceId, newPrice, serviceType }
  ) {
    const isAdmin = rootGetters['workspace/isAdmin']
    const supplierId = isAdmin
      ? $cookies.get('supplierId')
      : rootState['workspace'].currentUser.id
    const data = {
      data: {
        type: 'service',
        attributes: {
          [serviceType === 'qa' ? 'price_per_task' : 'price_per_word']:
            newPrice,
          ...(isAdmin ? { status: 'approved' } : { status: 'pending' })
        }
      }
    }
    await ApiService.put(`suppliers/${supplierId}/services/${serviceId}`, data)
      .then(async () => {
        await dispatch('getServices')
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async updateServiceStatus(
    { dispatch, rootState, rootGetters },
    { service, status }
  ) {
    const isAdmin = rootGetters['workspace/isAdmin']
    if (!isAdmin) {
      return
    }
    const supplierId = isAdmin
      ? $cookies.get('supplierId')
      : rootState['workspace'].currentUser.id
    const data = {
      data: {
        type: 'service',
        attributes: {
          ...service.attributes,
          status
        }
      }
    }
    await ApiService.put(`suppliers/${supplierId}/services/${service.id}`, data)
      .then(async () => {
        await dispatch('getServices')
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async deleteServiceFromAccount(
    { dispatch, rootGetters, rootState },
    { accountId, serviceId }
  ) {
    const isAdmin = rootGetters['workspace/isAdmin']
    if (!isAdmin) {
      return
    }
    const supplierId = isAdmin
      ? $cookies.get('supplierId')
      : rootState['workspace'].currentUser.id
    await ApiService.delete(
      `suppliers/${supplierId}/accounts/${accountId}/services/${serviceId}`
    )
      .then(async () => {
        await dispatch('supplier/getSupplierClients', null, { root: true })
        await dispatch('getServices')
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async updateAccountService(
    { dispatch, rootGetters },
    { accountId, service }
  ) {
    const data = {
      data: {
        type: 'supplier_accounts',
        status: service.status,
        attributes: {
          ...service.attributes
        }
      }
    }
    const supplierId = rootGetters['supplier/supplierId']
    await ApiService.put(
      `suppliers/${supplierId}/accounts/${accountId}/services/${service.id}`,
      data
    )
      .then(async () => {
        await dispatch('supplier/getSupplierClients', null, { root: true })
        await dispatch('getServices')
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async deleteAccount({ dispatch, rootGetters, rootState }, accountId) {
    const isAdmin = rootGetters['workspace/isAdmin']
    if (!isAdmin) {
      return
    }
    const supplierId = isAdmin
      ? $cookies.get('supplierId')
      : rootState['workspace'].currentUser.id
    await ApiService.delete(`suppliers/${supplierId}/accounts/${accountId}`)
      .then(async () => {
        await dispatch('supplier/getSupplierClients', null, { root: true })
        await dispatch('getServices')
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  }
}

const getters = {
  serviceType: (state) => (type) => {
    switch (type) {
      case 'qa':
        return i18n.t('shared.qa.title')
      case 'copyediting':
        return i18n.t('shared.label.copywriting')
      case 'translation':
        return i18n.t('shared.label.translation')
      case 'review':
        return i18n.t('shared.label.review')
      case 'ai_translation':
        return i18n.t('shared.label.ai_translation')
      default:
        break
    }
  },
  baseServices: (state) => {
    return state.services.filter(
      (service) =>
        service.attributes.type !== 'qa' &&
        service.attributes.type !== 'copyediting' &&
        service.attributes.type !== 'ai_translation'
    )
  },
  qaServices: (state) => {
    return state.services.filter((service) => service.attributes.type === 'qa')
  },
  copyeditingServices: (state) => {
    return state.services.filter(
      (service) => service.attributes.type === 'copyediting'
    )
  },
  aiTranslationServices: (state) => {
    return state.services.filter(
      (service) => service.attributes.type === 'ai_translation'
    )
  },
  indexOf: (state) => (service) => {
    return state.services.indexOf(service)
  }
}

export const services = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
