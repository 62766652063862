import ApiService from '@/api/new/services/api.service'
import i18n from '@/i18n'
import { $cookies } from '@/main'
import { router } from '@/router/index'
import FileSaver from 'file-saver'
import _ from 'underscore'

const state = {
  currentSupplierData: {},
  payoutsHistory: [],
  stripeConnectLink: '',
  supplierClients: [],
  serviceClients: [],
  cooperationAgreement: {}
}

const mutations = {
  setSupplierData(state, data) {
    state.currentSupplierData = data
  },
  setTasks(state, tasks) {
    state.tasks = {
      data: tasks.data,
      pages: {
        current: tasks.meta.current_page,
        total: tasks.meta.last_page
      }
    }
  },
  setCurrentPage(state, page) {
    state.currentPage = page
  },
  setCurrentConversation(state, conversation) {
    state.currentConversation = conversation
  },
  setPayoutsHistory(state, history) {
    state.payoutsHistory = history
  },
  setConnectLink(state, link) {
    state.stripeConnectLink = link
  },
  setAccounts(state, accounts) {
    state.supplierClients = accounts
  },
  setServiceClients(state, clients) {
    state.serviceClients = clients
  },
  setCooperationAgreement(state, agreement) {
    state.cooperationAgreement = agreement
  }
}

const actions = {
  async getSupplier({ dispatch }, email) {
    const data = {
      data: {
        attributes: { email }
      }
    }
    await ApiService.post(`suppliers`, data)
      .then(async (res) => {
        const supplierData = await res.data.data[0]
        if (supplierData) {
          await dispatch('getSupplierData', supplierData.id)
        }
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async getSupplierData({ commit }, id) {
    await ApiService.get(`suppliers/${id}`)
      .then((res) => {
        const supplierData = res.data.data
        commit('setSupplierData', supplierData)
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async updateCurrentSupplier(
    { dispatch, commit, rootState, rootGetters },
    supplier
  ) {
    const { avatar, ...attributes } = supplier
    const isAdmin = rootGetters['workspace/isAdmin']
    const supplierId = isAdmin
      ? $cookies.get('supplierId')
      : rootState['workspace'].currentUser.id

    const data = {
      data: {
        id: supplierId,
        type: 'supplier',
        attributes: {
          ...attributes
        }
      }
    }
    const { avatar: currentUserAvatar, ...currentUserAttributes } =
      rootState['workspace'].currentUser
    if (supplier.avatar) {
      const avatarData = new FormData()
      avatarData.append('data[id]', supplierId)
      avatarData.append('data[type]', 'supplier')
      avatarData.append('data[attributes][avatar]', avatar)
      await ApiService.postFormData(`suppliers/${supplierId}`, avatarData)
        .then(async (res) => {
          const supplier = res.data.data
          await commit('setSupplierData', supplier)
          await dispatch('workspace/getCurrentUser', null, { root: true })
          if (_.isEqual(attributes, currentUserAttributes)) {
            dispatch(
              'toast/success',
              {
                title: i18n.t('shared.toast.success.saved_title'),
                message: i18n.t('supplier.toast.success.update_profile_picture')
              },
              { root: true }
            )
          }
        })
        .catch((err) => {
          throw new Error(JSON.stringify(err))
        })
    }
    if (_.isEqual(attributes, currentUserAttributes)) {
      return
    }
    await ApiService.post(`suppliers/${supplierId}`, data)
      .then(async (res) => {
        const supplier = res.data.data
        await commit('setSupplierData', supplier)
        await dispatch('workspace/getCurrentUser', null, { root: true })
        dispatch(
          'toast/success',
          {
            title: i18n.t('shared.toast.success.saved_title'),
            message: i18n.t('shared.toast.success.update_profile_info')
          },
          { root: true }
        )
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },

  async getSupplierPayouts({ commit, rootGetters }) {
    const supplierId = rootGetters['supplier/supplierId']
    await ApiService.get(`suppliers/${supplierId}/payouts`)
      .then((res) => {
        commit('setPayoutsHistory', res.data.data)
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async getConnectLink({ commit, rootGetters }) {
    const supplierId = rootGetters['supplier/supplierId']
    await ApiService.get(`suppliers/${supplierId}/connect/login-link`)
      .then((res) => {
        commit('setConnectLink', res.data.data.attributes.login_link)
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async getStateSupplierClients({ state, dispatch }) {
    if (state.supplierClients.length === 0) await dispatch('getSupplierClients')
  },
  async getSupplierClients({ commit, rootGetters }) {
    const supplierId = rootGetters['supplier/supplierId']
    await ApiService.get(`suppliers/${supplierId}/accounts`)
      .then((res) => {
        commit('setAccounts', res.data.data)
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async getServiceClients({ commit, rootGetters }, serviceId) {
    const supplierId = rootGetters['supplier/supplierId']
    await ApiService.get(`suppliers/${supplierId}/services/${serviceId}`)
      .then((res) => {
        commit('setServiceClients', res.data.data.attributes.translation_teams)
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async prepareGdprDownload({ dispatch, getters }, options) {
    const data = {
      data: {
        type: 'supplier',
        attributes: {
          gdpr: options
        }
      }
    }
    await ApiService.post(`suppliers/${getters.supplierId}/gdpr/prepare`, data)
      .then(async () => {
        await dispatch('workspace/getCurrentUser', null, { root: true })
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async downloadGdprZip({ dispatch, getters }) {
    const downloadPath = getters.supplier.attributes.gdpr.path
    if (!downloadPath) {
      dispatch('toast/error', {
        message: i18n.t('shared.toast.error.download_gdpr')
      })
      return
    }
    const requestData = {
      method: 'post',
      url: `/laas/api/v1/suppliers/${getters.supplierId}/gdpr/download`,
      data: {},
      responseType: 'blob'
    }
    await ApiService.customRequest(requestData)
      .then((res) => {
        let fileSource = window.URL.createObjectURL(
          new Blob([res.data], { type: 'application/zip' })
        )
        FileSaver.saveAs(fileSource, `${getters.supplier.id}_gdpr_data.zip`)
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async requestSupplierDeletion({ dispatch, getters }, options) {
    const supplierId = getters.supplierId

    const data = {
      data: {
        type: 'supplier',
        attributes: {
          gdpr: options
        }
      }
    }
    await ApiService.post(`suppliers/${supplierId}/gdpr/request-delete`, data)
      .then(() => {
        dispatch(
          'toast/success',
          { message: i18n.t('shared.toast.success.request_delete') },
          { root: true }
        )
        dispatch('workspace/getCurrentUser', {}, { root: true })
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async deleteSupplierAccount({ getters }) {
    const supplierId = getters.supplierId

    await ApiService.delete(`suppliers/${supplierId}/gdpr`)
      .then(() => {
        router.push({ name: 'BaseLogin' })
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async getCooperationAgreement({ commit }) {
    await ApiService.get(`user-consents/file?type[]=supplier_terms&locale=en`)
      .then((res) => {
        const data = res.data.data.find(
          (e) => e.attributes.type === 'supplier_terms'
        )
        commit('setCooperationAgreement', data)
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async setSupplierConsents({ dispatch }, isConsent) {
    const data = {
      data: {
        type: 'user-consents',
        attributes: {
          consents: {
            supplier_terms: isConsent,
            supplier_data: isConsent
          }
        }
      }
    }
    await ApiService.put(`user-consents`, data)
      .then(async () => {
        dispatch(
          'toast/success',
          { message: i18n.t('supplier.toast.success.save_consents') },
          { root: true }
        )
        await dispatch('workspace/getCurrentUser', null, { root: true })
      })
      .catch((err) => {
        throw new Error(err.response.data)
      })
  }
}

const getters = {
  supplierConversations(state, getters, rootState, rootGetters) {
    const isAdmin = rootGetters['workspace/isAdmin']
    const supplier = isAdmin
      ? state.currentSupplierData
      : rootState['workspace'].currentUser
    if (!supplier.attributes.alerts) {
      return []
    }
    return supplier.attributes.alerts.conversations
  },
  supplier(state, getters, rootState, rootGetters) {
    const isAdmin = rootGetters['workspace/isAdmin']
    const supplier = isAdmin
      ? state.currentSupplierData
      : rootState['workspace'].currentUser
    return supplier
  },
  supplierId(state, getters, rootState, rootGetters) {
    const isAdmin = rootGetters['workspace/isAdmin']
    const supplierId = isAdmin
      ? $cookies.get('supplierId')
      : rootState['workspace'].currentUser.id
    return supplierId
  },
  supplierClient: (state) => (clientId) => {
    const account = state.supplierClients.find(
      (account) => account.id === clientId
    )
    if (!account) return {}
    return account
  },
  isFreemiumClient: (state) => (clientId) => {
    const account = state.supplierClients.find(
      (account) => account.id === clientId
    )
    if (!account) return false
    return (
      account.attributes.subscription.attributes.plan_identifier === 'freemium'
    )
  },
  baseServiceClients: (state) => {
    return state.serviceClients.filter(
      (service) => service.attributes.price_per_word > 0
    )
  },
  qaServiceClients: (state) => {
    return state.serviceClients.filter(
      (service) => service.attributes.price_per_task > 0
    )
  },
  isPendingDeletion: (state, getters) => {
    return !!getters.supplier.attributes.gdpr.requested_deletion_at
  }
}

export const supplier = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
